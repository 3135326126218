@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background: $color-black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	text-align: center;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	position: relative;

	> img {
		width: 100%;
	}

	> .container {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		> .socials {
			list-style: none;
			margin: rem(50) 0 0 0;
			padding: 0;

			@include tablet-down {
				margin-top: rem(25);
			}

			> li {
				display: inline-block;
				font-size: rem(30);
				vertical-align: middle;

				+ li {
					margin-left: rem(25);
				}

				> a {
					color: $color-white;

					@include hover-focus {
						color: $color-pink;
					}
				}
			}
		}

		> h1 {
			left: 15px;
			margin: 0;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);

			> img {
				margin: 0 auto;

				@include tablet-down {
					max-width: 75%;
				}
			}
		}
	}
}

main {
	#about {
		> img {
			width: 100%;
		}

		> .container {
			margin-top: -20%;

			.owl-carousel {
				padding: 0 rem(30);

				.owl-nav {
					> button {
						font-size: rem(30);
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						&.owl-prev {
							left: 0;
						}

						&.owl-next {
							right: 0;
						}
					}
				}
			}
		}
	}
}

footer {
	padding: rem(40) 0;
	text-transform: uppercase;

	img {
		margin: 0 auto rem(20) auto;
	}

	.copyright {
		p {
			font-size: rem(12);
			letter-spacing: letter-spacing(300);
			line-height: line-height(22, 12);
			margin: 0;
			text-indent: text-indent(300);
		}
	}
}
